<template>
    <div>
        <div class="page-header">权限代码列表</div>
        <div style="margin:20px;text-align:right;">
            <el-button @click="addParent()" size="small" type="primary">添加导航菜单</el-button>
        </div>
        <div class="block" style="padding:20px;">
            <div class="list" v-for="(item,idx) in list" :key="idx">
                <div class="list-header flex">
                    <div class="flex-1">{{item.name}}</div>
                    <el-button type="text" size="small" @click="addSon(item)">
                        <span class="icon iconfont">+</span>&nbsp;添加子菜单
                    </el-button>
                    <el-button type="text" size="small" @click="editParent(item)">
                        <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
                    </el-button>
                    <el-button type="text" size="small" @click="delParent(item)">
                        <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
                    </el-button>
                </div>
                <div class="flex" style="line-height:40px;padding-left: 40px;padding-right: 20px;" v-for="(item1,idx1) in item.children" :key="idx1">
                    <div class="item-header" style="min-width:100px;max-width:200px;">{{item1.name}}</div>
                    <div class="item-header flex-1">地址：{{item1.url}}</div>
                    <div>
                        <el-button type="text" size="small" @click="editSon(item,item1)">
                            <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
                        </el-button>
                        <el-button type="text" size="small" @click="delSon(item1)">
                            <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditClass v-if="popTitle=='添加导航菜单分类' || popTitle=='修改导航菜单分类'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></EditClass>
            <EditNav v-if="popTitle=='添加导航菜单' || popTitle=='修改导航菜单'" :form="ruleForm1" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></EditNav>
        </el-dialog>
    </div>
</template>
<script>
    import { getNavs,delNav } from '@/service/purview';
    import EditClass from './EditClass.vue';
    import EditNav from './EditNav.vue';
    export default {
        components: {
            EditClass,EditNav
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                list:[],
                ruleForm:"",
                ruleForm1:"",
            }
        },
        mounted() {

        },
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getNavs().then(rst => {
                    if (rst && rst.length>0) {
                        this.list = rst;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            addParent() {
                this.popTitle = "添加导航菜单分类";
                this.popVisible = true;
                this.ruleForm = {
                    name:"",url:"",
                    pid:0,priv_id:0,desc:"",
                };
            },
            editParent(item) {
                this.popTitle = "修改导航菜单分类";
                this.popVisible = true;
                this.ruleForm = {
                    nav_id:item.id,
                    name:item.name,url:item.url,
                    pid:0,priv_id:0,desc:item.desc,
                };
            },
            delParent(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delNav({nav_id:item.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            addSon(item) {
                this.popTitle = "添加导航菜单";
                this.popVisible = true;
                this.ruleForm1 = {
                    name:"",url:"",
                    pid:item.id,priv_id:[],desc:"",
                };
            },
            editSon(item,item1) {
                this.popTitle = "修改导航菜单";
                this.popVisible = true;
                this.ruleForm1 = {
                    nav_id:item1.id,
                    pid:item.id,
                    name:item1.name,url:item1.url,
                    priv_id:item1.priv_id,desc:item1.desc,
                };
            },
            delSon(item1) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delNav({nav_id:item1.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        margin-bottom: 20px;
        border:1px solid #ebeef5;
    }
    .list-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        border-bottom: 1px solid #c0c4cc;
        padding:0 20px;
    }
    .item-header {
        font-size: 14px;
        line-height: 35px;
    }
    .icon {
        font-size: 12px;
    }
</style>