<template>
    <div style="padding:0 20px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="菜单名称">
                <el-input placeholder="请输入菜单名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item prop="url" label="URL">
                <el-input placeholder="请输入URL地址" v-model="ruleForm.url"></el-input>
            </el-form-item>
            <el-form-item label="显示权限">
                <div class="list" v-for="(item,idx) in selectList" :key="idx">
                    <div>{{item.name}}</div>
                    <div style="padding-left:10px;line-height:30px;">
                        <!-- <el-checkbox-group v-model="checkList">
                            <el-checkbox v-for="(item1,idx1) in item.privs" :key="idx1" :label="item1.id">
                                {{item1.name}}
                            </el-checkbox>
                        </el-checkbox-group> -->
                        <el-radio-group v-model="ruleForm.priv_id">
                            <el-radio v-for="(item1,idx1) in item.privs" :key="idx1" :label="item1.id">{{item1.name}}</el-radio>
                            <el-radio v-if="idx==0" :label="0">不判断权限</el-radio>
                        </el-radio-group>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="菜单说明">
                <el-input type="textarea" :rows="3" placeholder="请输入菜单说明" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <div style="padding-top:20px;">
                <el-button type="primary" @click="submitForm()" style="width: 100%;">提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { getCanAssignPrivs,addNav,setNav } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '菜单名称', trigger: 'blur' }
                    ],
                    url: [
                        { required: true, message: '请输入URL地址', trigger: 'blur' }
                    ],
                    // desc: [
                    //     { required: true, message: '请输入菜单说明', trigger: 'blur' }
                    // ]
                },
                ruleForm:{
                    pid:"",
                    name:"",url:"",
                    priv_id:"",desc:"",
                },
                checkList:[],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                    this.checkList = this.form.priv_id;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.checkList = this.form.priv_id;
            console.log(this.form);
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getCanAssignPrivs().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            getRowKeys(row) {
                return row.index;
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                this.ruleForm.priv_id = this.checkList;
                if (this.popTitle == "添加导航菜单") {
                    addNav(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "修改导航菜单") {
                    setNav(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            }
        }
    }
</script>
<style scoped>
    
</style>