<template>
    <div style="max-width: 300px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" class="login-form">
            <el-form-item prop="name" label="菜单名称">
                <el-input placeholder="请输入菜单名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <!-- <el-form-item prop="url" label="URL">
                <el-input placeholder="请输入URL地址" v-model="ruleForm.url"></el-input>
            </el-form-item> -->
            <el-form-item prop="desc" label="菜单说明">
                <el-input type="textarea" :rows="3" placeholder="请输入菜单说明" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <el-button type="primary" @click="submitForm()" style="width: 100%;">提交</el-button>
        </el-form>
    </div>
</template>
<script>
    import { addNav,setNav } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '菜单名称', trigger: 'blur' }
                    ],
                    // url: [
                    //     { required: true, message: '请输入URL地址', trigger: 'blur' }
                    // ],
                    desc: [
                        { required: true, message: '请输入菜单说明', trigger: 'blur' }
                    ]
                },
                ruleForm:"",
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
        },
        mounted() {},
        methods: {
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit() {
                if (this.popTitle == "添加导航菜单分类") {
                    addNav(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "修改导航菜单分类") {
                    setNav(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },
        }
    }
</script>
<style scoped>

</style>